<template>
  <svg viewBox="0 0 33 32"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.555176"
          width="32"
          height="32"
          rx="4"
          fill="#0CAA41"
    />
    <path d="M16.5551 22.26L9.50209 26.208L11.0771 18.28L5.14209 12.792L13.1691 11.84L16.5551 4.5L19.9411 11.84L27.9681 12.792L22.0331 18.28L23.6081 26.208L16.5551 22.26Z"
          fill="white"
    />
  </svg>
</template>
